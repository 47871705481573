body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}

.modal-tall .modal-content {
  min-height: 30vh;
}

.min-w-5 {
  min-width: 10vw;
}

.min-w-10 {
  min-width: 10vw;
}

.min-w-20 {
  min-width: 10vw;
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;

  max-width: 30vw;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.react-autocomplete-input {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.15);
  bottom: auto;
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  display: block;
  font-size: 14px;
  list-style: none;
  padding: 1px;
  text-align: left;
  z-index: 20000;
  overflow: scroll;
  max-height: 15vh;
}

.react-autocomplete-input > li {
 cursor: pointer;
 padding: 10px;
 min-width: 100px;
}

.react-autocomplete-input > li.active {
 background-color: #337ab7;
 color: #fff;
}

.line {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  height: 0.2rem;
  background: #f0553a;
  float: left;
}

.line-vertical {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 0.2rem;
  min-height: 100%;
  background: #f0553a;
  float: left;
}

.border-left-right {
  border-left: solid black 0.2rem;
  border-right: solid black 0.2rem;
}

.border-top-bottom {
  border-top: solid black 0.2rem;
  border-bottom: solid black 0.2rem;
}

.margin-left-right {
  margin-left: 1rem;
  margin-right: 1rem;
}

.margin-top-bottom {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.add-button {
  margin-left: 1rem;
  margin-right: 1rem;
}

.delete-button {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}

.column-spacing {
  .line {
    background: #00ffff;
  }
}

.row-spacing {
  .line, .line-vertical {
    background: slateblue;
  }
}

.label-width {
  .line {
    background: seagreen;
  }
}


.label-height {
  .line, .line-vertical {
    background: #daee01;
  }
}

.header-height {
  .line, .line-vertical {
    background: #ffa500;
  }
}

.header-width {
  .line, .line-vertical {
    background: #ffa500;
  }
}

.header-vertical-offset {
  .line, .line-vertical {
    background: #ccccff;
  }
}

.header-horizontal-offset {
  .line {
    background: #ccccff;
  }
}

.grey-block {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5
}

.table {
  border: 1px solid #e1e6eb;
  background-color: #fff;
}

.table td {
  background-color: unset;
  color: unset;
}

.table thead th {
  background-color: #e1e6eb;
  color: #697582;
  font-weight: 600;
}

a {
  color: #0088a9;
  text-decoration: none;
}

.button-group > * + *,
{
  margin-left: 0.5rem;
}

.edit-pipeline > * + * {
  margin-left: 1em;
}

.color-picker {
  font-size: 14px;
}

//This is for tooltips in modals (pipeline job simulator) to show up
.crc-tooltip-tether {
  z-index: 1050;
}
