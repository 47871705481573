:root {
    /**
     * Cimpress Style Guide:
     * http://share.vistaprint.net/global/share/Global_Comms_Team/Public/Cimpress_Brand_Resource_Center/Style_Guide/2014_11_17_Cimpress_Style_Guide_v1-0.pdf
     */
    --color-text:     #333;
    --color-text-muted: #888;
    --color-bg:       #f2f2f2;
    --color-head:     #eff3f5;
    --color-hover:    yellow;
    --color-link:     #41b5dc;
    --color-muted:    color(var(--color-text) lightness(+60%));
    --color-success:  color(green             lightness(+55%) saturation(-20%));
    --color-danger:   color(red               lightness(+20%) saturation(-20%));
    --color-brand:    #3A414C; /* Cobalt Grey */
    --color-indicate: #0275d8; /* blue (PMS: 306) */
    --color-highlight:#ffd73f;
    --color-accent:   #F0563A; /* Persimmon Orange */
    --color-accent2:  #f3f3f3;
    --color-inactive: #efedeb;
    --color-header:   #373a3c; /* Bootstrap `.bg-inverse` color */
    /*--color-platform-bg: #eff3f5;*/
    --color-platform-bg: #f0f3f5;
    --color-platform-line: #e1e6eb;

    /**
     * Why defer to system fonts for body content? Here's one take:
     * https://medium.com/designing-medium/system-shock-6b1dc6d6596f#.k30o6zbfi
     */
    --font-stack-body: system-ui;
    --font-stack-brand: "Gotham Rounded SSm A", "Gotham Rounded SSm B", "Gotham Rounded", "Gotham", var(--font-stack-body);
    --font-stack-display: var(--font-stack-brand);
    --font-stack-mono: "Consolas", "Liberation Mono", "Menlo", "Monaco", "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", "Courier New", "Courier", monospace;
    --font-stack-loading: 'Redacted', var(--font-stack-body);

    --timing-curve-bounce: cubic-bezier(0.87, -0.41, 0.19 , 1.44);
    --timing-curve-snappy: cubic-bezier(0.75,  0   , 0.175, 1   );
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(0.4rem); }
    to   { opacity: 1; transform: translateY(  0   ); }
  }

  @keyframes slideInLeft {
    from { opacity: 0; transform: translateX(75vw); }
    to   { opacity: 1; transform: translateX( 0  ); }
  }

  @keyframes zoomIn {
    from { opacity: 0.5; transform: scale3d(0.8, 0.8, 0.8); }
    50%  { opacity: 1; }
  }

  /* Thanks https://github.com/daneden/animate.css/blob/master/source/bouncing_entrances/bounceIn.css a479864 */
  @keyframes bounceIn {
    from, 20%, 40%, 60%, 80%, to {
      animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0%  { opacity: 0; transform: scale3d(.3, .3, .3); }
    20% { transform: scale3d(1.1, 1.1, 1.1); }
    40% { transform: scale3d(.9, .9, .9); }
    60% { opacity: 1; transform: scale3d(1.03, 1.03, 1.03); }
    80% { transform: scale3d(.97, .97, .97); }
    to  { opacity: 1; transform: scale3d(1, 1, 1); }
  }

  /* Thanks http://codepen.io/yoksel/pen/XJbzrO */
  @keyframes strokeOffset {
    from {
      stroke-dashoffset: 35%;
      stroke-dasharray: 0 87.5%;
    }
  }
