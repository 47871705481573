@import "../vars.scss";

.fluid-card-list .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  .col-xs-6,
  .col-xs-4,
  .col-sm-4,
  .col-sm-6,
  .col-md-4 {
    float: none !important;
    display: flex !important;
  }

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    h5.title {
      color: var(--color-accent);
      text-align: center;
    }

    p.description {
      color: var(--color-brand);
      text-align: center;
      font-size: 14px;
    }

    p,
    h4,
    .link {
      padding: 0 8px;
    }

    p {
      flex: 1 0 auto;
      padding: 0 15px;
    }

    .link {
      margin: 10px auto 20px;
    }
  }

  @media (max-width: 550px) {
    .col-xs-6 {
      width: 100%;
    }

    .card {
      min-height: 0;
    }
  }
}
